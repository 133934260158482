.projectsPanel {
	padding: 0 20px;
	margin-top: 110px;
	max-height: calc(100vh - 220px);
	overflow-y: auto;

	// Add the fade in/out effect to the top and bottom of the scrolling container so that as the
	// user scrolls, the text fades in and out.
	mask: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 30px),
		linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 30px);
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	mask-composite: exclude;
}