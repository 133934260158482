.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

button.logo {
	position: absolute;
	top: 20px;
	left: 20px;
	border: 3px solid #0460B0;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;

	img {
		filter: invert(1);
		width: 60px;
		margin-top: -5px;
	}
}