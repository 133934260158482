body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
	height: 100vh;
	overflow: hidden;
	display: flex;
	font-family: 'Anton', sans-serif;
	justify-content: center;
	align-items: center;
}

#root {
	width: 100vw;
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	color: #1e88e5;

	&:focus {
		color: hsl(180, 100%, 80%);
	}

	&:visited {
		color: #04579f;
	}
}